var $jscomp = $jscomp || {};
$jscomp.scope = {};
$jscomp.createTemplateTagFirstArg = function (b) {
  return (b.raw = b);
};
$jscomp.createTemplateTagFirstArgWithRaw = function (b, a) {
  b.raw = a;
  return b;
};
var CSVBoxImporter = function (b, a, c, e) {
  a = void 0 === a ? {} : a;
  c = void 0 === c ? null : c;
  this.configuration = e = void 0 === e ? {} : e;
  this.log("Importer initialisation started");
  this.shouldOpenModalonIframeLoad = this.isIframeLoaded = !1;
  this.slug = b;
  this.data = a;
  this.key = this.randomString();
  this.columns = [];
  this.options = [];
  this.configuration.data_location &&
    (this.data_location = this.configuration.data_location);
  c && "function" == typeof c && (this.callback = c);
  var f = this;
  "complete" === document.readyState &&
    (f.log("document readyState is complete"),
    f.configuration.lazy || f.setUpImporter());
  this.log("Setting up DOMContentLoaded event listener " + document.readyState);
  document.addEventListener("DOMContentLoaded", function () {
    f.log("Event: DOMContentLoaded");
    f.configuration.lazy || f.setUpImporter();
  });
  this.configuration.lazy || this.setUpImporter();
  this.log("Importer initialisation done");
};
CSVBoxImporter.prototype.initImporter = function () {
  try {
    this.log("Called setUpImporter();");
    this.isIframeLoaded = this.isModalShown = !1;
    this.setupMessageListener();
    var b = document.createElement("style");
    b.type = "text/css";
    "textContent" in b
      ? (b.textContent =
          ".csvbox-component {position: fixed;top: 0;bottom: 0;left: 0;right: 0;z-index:2147483647;}.csvbox-component iframe{height: 100%;width: 100%;position: absolute;}")
      : (b.innerText =
          ".csvbox-component {position: fixed;top: 0;bottom: 0;left: 0;right: 0;z-index:2147483647;}.csvbox-component iframe{height: 100%;width: 100%;position: absolute;}");
    document.body.appendChild(b);
    this.id = "csvbox-embed-" + this.key;
    this.holder = document.createElement("div");
    this.holder.classList.add("csvbox-component");
    this.holder.style.display = "none";
    var a = document.createElement("iframe");
    this.iframe = a;
    b = "app.csvbox.io";
    this.configuration.customDomain && (b = this.configuration.customDomain);
    var c = "https://" + b + "/embed/";
    this.data_location &&
      (c = "https://" + this.data_location + "-" + b + "/embed/");
    var e = c + this.slug,
      f;
    e += "?debug=" + !(null == (f = this.configuration) || !f.debug);
    e += "&source=embedCode";
    this.data_location && (e += "&preventRedirect");
    this.configuration.language &&
      (e += "&language=" + this.configuration.language);
    this.log("Loading url " + e);
    a.setAttribute("src", e);
    a.setAttribute("data-csvbox-slug", this.slug);
    a.frameBorder = 0;
    this.holder.id = this.id;
    this.holder.appendChild(a);
    document.body.appendChild(this.holder);
    var d = this;
    a.onload = function () {
      d.isIframeLoaded = !0;
      d.shouldOpenModalonIframeLoad &&
        ((d.shouldOpenModalonIframeLoad = !1), d.openModal());
      a.contentWindow.postMessage({ customer: d.data }, "*");
      a.contentWindow.postMessage({ columns: d.columns }, "*");
      a.contentWindow.postMessage({ options: d.options }, "*");
      var g;
      null == (g = d.onReady) || g.call(d);
      d.log("iframe is ready.");
    };
  } catch (g) {
    this.log("Importer initialisation error " + g);
  }
};
CSVBoxImporter.prototype.setUpImporter = function () {
  var b = document.getElementById("csvbox-embed-" + this.key);
  b
    ? (this.log("Old csvbox component present on page"),
      b.firstChild.getAttribute("data-csvbox-slug") !== this.slug &&
        (this.log(
          "Slug has been changed, csvbox component refresh is required"
        ),
        b.remove(),
        this.initImporter()))
    : this.initImporter();
};
CSVBoxImporter.prototype.setUser = function (b) {
  this.data = b;
  this.iframe &&
    this.iframe.contentWindow.postMessage({ customer: this.data }, "*");
};
CSVBoxImporter.prototype.listen = function (b, a) {
  a = void 0 === a ? null : a;
  if ("function" == typeof a)
    switch (b) {
      case "onReady":
        this.onReady = a;
        break;
      case "onClose":
        this.onClose = a;
        break;
      case "onSubmit":
        this.onSubmit = a;
    }
};
CSVBoxImporter.prototype.setupMessageListener = function () {
  var b = this;
  window.addEventListener(
    "message",
    function (a) {
      if ("mainModalHidden" === a.data) {
        b.holder.style.display = "none";
        b.isModalShown = !1;
        var c;
        null == (c = b.onClose) || c.call(b);
      }
      "uploadSuccessful" === a.data &&
        b.callback &&
        "function" == typeof b.callback &&
        b.callback(!0);
      "uploadFailed" === a.data &&
        b.callback &&
        "function" == typeof b.callback &&
        b.callback(!1);
      if ("object" == typeof a.data)
        if (a.data.type && "data-on-submit" == a.data.type) {
          c = a.data.data;
          c.column_mappings = a.data.column_mapping;
          delete c.unique_token;
          var e;
          null == (e = b.onSubmit) || e.call(b, c);
        } else if (a.data.type && "data-push-status" == a.data.type)
          if ("success" == a.data.data.import_status) {
            var f;
            if (null == a ? 0 : null == (f = a.data) ? 0 : f.row_data) {
              var d = a.data.headers,
                g = [],
                q = a.data.dynamicColumnsIndexes,
                r = a.data.virtualColumnsIndexes || [];
              a.data.row_data.forEach(function (m) {
                var h = {},
                  n = {},
                  p = {};
                m.data.forEach(function (k, l) {
                  void 0 == k && (k = "");
                  q.includes(l)
                    ? (n[d[l]] = k)
                    : r.includes(l)
                    ? (p[d[l]] = k)
                    : (h[d[l]] = k);
                });
                if (null == m ? 0 : m.unmapped_data)
                  h._unmapped_data = m.unmapped_data;
                n && 0 < Object.keys(n).length && (h._dynamic_data = n);
                p && 0 < Object.keys(p).length && (h._virtual_data = p);
                g.push(h);
              });
              a = a.data.data;
              a.rows = g;
              delete a.unique_token;
              b.callback(!0, a);
            } else (a = a.data.data), delete a.unique_token, b.callback(!0, a);
          } else (a = a.data.data), delete a.unique_token, b.callback(!1, a);
    },
    !1
  );
  this.log("Message listener initialised.");
};
CSVBoxImporter.prototype.openModal = function () {
  this.configuration.lazy && this.setUpImporter();
  this.isIframeLoaded
    ? this.isModalShown ||
      ((this.isModalShown = !0),
      this.holder
        .querySelector("iframe")
        .contentWindow.postMessage("openModal", "*"),
      (this.holder.style.display = "block"))
    : ((this.shouldOpenModalonIframeLoad = !0),
      this.log("iframe not loaded yet. Modal will open once iframe is loaded"));
};
CSVBoxImporter.prototype.randomString = function () {
  for (var b = "", a = 0; 15 > a; a++)
    b += "abcdefghijklmnopqrstuvwxyz0123456789".charAt(
      Math.floor(36 * Math.random())
    );
  return b;
};
CSVBoxImporter.prototype.setDynamicColumns = function (b) {
  this.columns = b;
  this.iframe &&
    this.iframe.contentWindow.postMessage({ columns: this.columns }, "*");
};
CSVBoxImporter.prototype.setOptions = function (b) {
  this.options = b;
  this.iframe &&
    this.iframe.contentWindow.postMessage({ options: this.options }, "*");
};
CSVBoxImporter.prototype.log = function (b) {
  (this.configuration.debug ||
    (sessionStorage &&
      "true" == sessionStorage.getItem("CSVBOX_DEBUG_FLAG"))) &&
    console.log("[CSVBox]", b);
};
null != document.querySelector("[data-csvbox]") &&
  (document.onreadystatechange = function () {
    "complete" === document.readyState
      ? (document.querySelector("[data-csvbox]").disabled = !1)
      : (document.querySelector("[data-csvbox]").disabled = !0);
  });
